/* Cookies */

setCookie = function(name, days) {
  var d = new Date();
  d.setTime(d.getTime() + 1000 * 60 * 60 * 24 * days);
  document.cookie = name + "=true;path=/;expires=" + d.toGMTString() + ';';
};

checkForCookie = function(name) {
  if (document.cookie.indexOf(name) === -1) {
    return false;
  } else {
    return true;
  }
};

jQuery(document).ready( function($){

});

jQuery(document).ready(function($){

  /*
   *
   * Responsive menu toggle
   *
   */
  var $menuToggle = $('#js-mobile-menu');
  var $menuList = $('#js-navigation-menu');

  $menuToggle.on('click', function(e) {
    e.preventDefault();
    $menuList
    .toggleClass('show')
    .slideToggle(function(){
      if ($menuList.is(':hidden')) {
        $menuList.removeAttr('style');
      }
    });
  });

  /*
   *
   * Scroll to...
   *
   */
  $('a[href^="#"]').on('click', function(e) {

    e.preventDefault();

    $('.menu-item > a.active').removeClass('active');
    $(this).addClass('active');

    $('html, body').animate({ scrollTop: $(this.hash).offset().top - 84 }, 500);

    if ($menuList.is(':visible')) {
      $menuToggle.trigger('click');
    }

    return true;
  });

  /*
   *
   * Cookie notification
   *
   */
  $(function() {
  
    if (!checkForCookie('hideCookieNotification')) {
  
      $('.module-notification.-cookie').show();
    }
  
  });
  
  $(document).on('click touchstart', '.js-close-cookie-notification', function(e) {
    e.preventDefault();
    setCookie('hideCookieNotification', 365);
    $(this).parent().slideUp('fast');
  });

});